import {
  options
} from 'less';
import {
  http
} from './http'
import {
  getLocalStorage
} from '@/common/storage'
const basePath = '/open-cloud-admin'

function loginPass(options) {
  return http.post(`${basePath}/api/authority/cloud/verification/login`, options);
}

function getUserInfo(options) {
  return http.post(`${basePath}/api/cloud/user/getInfo`, options)
}

function sendCode(options) {
  return http.post(`${basePath}/api/authority/cloud/message/sendSms`, options)
}

function loginCode(options) {
  return http.post(`${basePath}/api/authority/cloud/message/validateSmsAndLogin`, options)
}

function getNotice(options) {
  const accessToken = getLocalStorage('accessToken')
  return http.post(`${basePath}/api/cloud/latest/news/findFrontPage`, {
    headers: {
      accessToken
    },
    ...options
  })
}

function getIndustryList(options) {
  return http.post(`${basePath}/api/manage/industry/getList`, options)

}
function addNewUser(options){
  return http.post(`${basePath}/api/admin/register/user/info`,options)
}
export {
  loginPass,
  getUserInfo,
  sendCode,
  loginCode,
  getNotice,
  getIndustryList,
  addNewUser
};