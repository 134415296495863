<template>
  <div class="tab-nav">
     <ul>
       <li v-for="(item,index) in navs" :key="index" :class="active === index?'active':''" @click="changeNav(index)">
          {{ item }}
       </li>
     </ul>
  </div>
</template>
<script setup>
import { defineProps,defineEmits } from 'vue';
const emit = defineEmits(["change"]); 
   const props = defineProps({
     navs:{
       type:Array,
       default:()=>{
         return [
           '标签1','标签2'
         ]
       }
     },
     active:{
       type:Number,
       default:0
     }
   })
   const changeNav  =(index)=>{
    emit('change',index)
   }
</script>
<style lang="less" scoped>
.tab-nav{
  ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid var(--blue100);
    li{
      padding:12px 24px 18px;
      font-size: 20px;
      cursor: pointer;
      &.active{
        color:var(--blue600);
        font-weight: 600;
        position: relative;
        &:after{
          display: block;
          content: "";
          width: 60px;
          border-top:4px solid var(--blue500);
          position: absolute;
          left:50%;
          bottom:-1px;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>