<script setup>

</script>

<template>
 <router-view />
</template>

<style lang="less">

</style>
