import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from "vue-router";
import {
  nextTick
} from 'vue'

const routes = [
  {
    path: '/',
    name: "index",
    // component: () => import("@/pages/index.vue"),
    component: () => import("@/pages/login/login.vue"),
    props: (route) => ({
      ...route.query
    })
  },
  // {
  //   path: '/login',
  //   name: "login",
  //   component: () => import("@/pages/login/login.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/product',
  //   name: "product", //服务管理 
  //   component: () => import("@/pages/product.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/operation',
  //   name: "operation", //运营管理
  //   component: () => import("@/pages/operation.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/command',
  //   name: "command", //指挥管理
  //   component: () => import("@/pages/command.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/live',
  //   name: "live", //直播管理
  //   component: () => import("@/pages/live.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/civilization',
  //   name: "civilization", //文明实践
  //   component: () => import("@/pages/civilization.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/creation',
  //   name: "creation", //创作管理
  //   component: () => import("@/pages/creation.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/resource',
  //   name: "resource", //资源管理
  //   component: () => import("@/pages/resource.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/show',
  //   name: "show", //活动show
  //   component: () => import("@/pages/show.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/data',
  //   name: "data", //数据管理
  //   component: () => import("@/pages/data.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/paper',
  //   name: "paper", //纸媒管理
  //   component: () => import("@/pages/paper.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/eBusiness',
  //   name: "eBusiness", //电商管理
  //   component: () => import("@/pages/eBusiness.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/order',
  //   name: "order", //预约管理 
  //   component: () => import("@/pages/order.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/study',
  //   name: "study", //学习管理
  //   component: () => import("@/pages/study.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/invite',
  //   name: "invite", //招聘管理
  //   component: () => import("@/pages/invite.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import("@/pages/register.vue"),
  //   props: (route) => ({
  //     ...route.query
  //   })
  // }


]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {

    if (to.name === 'index') {
      if (savedPosition) {
        return savedPosition
      }
    } else {
      return {
        top: 0
      }
    }
  },
  routes: [
    ...routes
  ]
});
router.afterEach((to, from) => {
  if (to.name == 'index' && to.query?.anchor) {
    const anchor = to.query?.anchor
    nextTick(() => {
      document.getElementById(anchor).scrollIntoView({
        behavior: "smooth"
      })
    })
  }
  nextTick(() => {
    let wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wow.init();
  })
})

export default router;