import "et-common";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style/style.less'
import 'ant-design-vue/dist/antd.css';
import waves  from '@/assets/waves'
$$.router = router
import Component from "@/components/index";
const app = createApp(App)
app.directive('waves',waves)
app.use(router).use(Component).mount('#app')
