import {
  Http
} from "et-common";
import {
  message
} from 'ant-design-vue';
export const http = new Http({
  timeout: 1000 * 10,
  request(config) {

    return config;
  },
  response({
    data,
    config
  }) {
    const {
      success,
      fail
    } = config;
    const promise = Promise.resolve(data);
    return promise.then(({
      code,
      data,
      msg
    }) => {
      switch (code) {
        case 0:
          success && success(data);
          break;
        default:
          fail ? fail(msg) : message.error(msg);
      }
      return data;
    });
  },
  error(error) {
    const {
      response
    } = error;

    // network error
    if (!response) {
      return;
    }

    // network success
    switch (response.status) {
      case 403:
      case 404:
      case 500:
        console.log("...403");
        break;
      default:
        console.log("...default");
    }
    return error;
  },
});