<template>
  <div id="header" class="com-header" :class="{ fixed: hedaerFixed }">
    <div class="common-container">
      <div class="logo">
        <a href="##">常德开放平台</a>
      </div>
      <div id="mainNav">
        <ul id="subNav" class="clearfix">
          <li
            class="nav-item"
            :class="actived === index ? 'active' : ''"
            @click="pageLink(li)"
            v-for="(li, index) in data.menus"
            :key="index"
            @mouseenter="hover(index)"
          >
            <!-- :class="(index ==1 && navHide)?'cricle':''" -->
            <a>{{ li.name }}</a>
          </li>
        </ul>
      </div>
      <div class="handle" v-waves v-if="noLogin">
        <a @click="toLogin">登录</a>
        <span class="line"></span>
        <a @click="linkToRegister">注册</a>
      </div>
      <div class="userBox" v-else>
        <a :href="linkAdmin">控制台</a>
        <div class="user">
          <div class="photo">
            <img :src="user.photo" alt />
          </div>
          <span>{{ user.name }}</span>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="sec-nav" v-show="navHide" @mouseleave="navHide = false">
        <div class="common-container">
          <ul>
            <li v-for="(item, index) in data.openList" :key="index">
              <a @click="pageLink2(item.link)">
                <h4>{{ item.title }}</h4>
                <p class="two-text-hide">{{ item.brief }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>

</template>
<script setup>
import { reactive, ref } from "vue-demi";
import { defineProps } from "vue";
import { getLocalStorage } from "@/common/storage";
import { getUserInfo } from "@/common/api";
import { TabPane } from "ant-design-vue";
const props = defineProps({
  actived: {
    type: Number,
    default: 0,
  },
});
const activeKey = ref("1");
const navHide = ref(false);
const hedaerFixed = ref(false);
const linkAdmin = ref(location.origin + '/oadmin/')
const data = reactive({
  menus: [
    {
      name: "首页",
      router: "index",
    },
    {
      name: "能力版图",
      router: "index",
      anchor: "open",
    },
    {
      name: "解决方案",
    },
    {
      name: "精选案例",
      router: "operation",
    },
  ],
  openList: [
    {
      title: "服务解决方案",
      brief: "面向诸多领域的抽象业务场景，提供多端适配的海量服务接口。",
      link: "product",
    },
    {
      title: "直播解决方案",
      brief: "为用户提供多渠道个性化的云端直播SaaS服务，支持各类直播场景。",
      link: "live",
    },
    {
      title: "活动解决方案",
      brief: "拥有多种成熟的活动方案，满足各类活动场景，分析活动运营数据。",
      link: "show",
    },
    {
      title: "文明实践解决方案",
      brief: "整合文明实践服务和志愿者、团队资源，构建一站式一体化服务。",
      link: "civilization",
    },
    {
      title: "指挥解决方案",
      brief: "支持各区全面整合“报、台、网、微、端、屏”等媒体资源。",
      link: "command",
    },
    {
      title: "创作解决方案",
      brief: "为用户提供功能强大的文稿编辑器、实现多渠道发稿功能。",
      link: "creation",
    },
    {
      title: "资源解决方案",
      brief: "易用，稳定的媒资解决方案。",
      link: "resource",
    },
    {
      title: "移动管理平台",
      brief: "移动端产品发布平台。",
      link: "",
    },
    {
      title: "纸媒解决方案",
      brief: "统一管理报刊、杂志等纸媒的数字化内容。",
      link: "paper",
    },
    {
      title: "电商解决方案",
      brief: "为各行业全场景客户提供多渠道易使用的一体化电商解决方案。",
      link: "eBusiness",
    },
    {
      title: "预约解决方案",
      brief: "公园场馆购票、运动场纪念馆的入场预约及核销。",
      link: "order",
    },
    {
      title: "学习解决方案",
      brief: "提供课程发布、课件管理、直播课堂、课堂互动、学习积分等服务。",
      link: "study",
    },
    {
      title: "招聘解决方案",
      brief: "帮助企业建立自己的人才库，智能匹配人才，助力人力资源管理。",
      link: "invite",
    },
    {
      title: "数据解决方案",
      brief: "助力企业和政府建立数据治理体系，促进数据的有效分析和利用。",
      link: "data",
    },
    {
      title: "xxx",
      brief: "助力企业和政府建立数据治理体系，促进数据的有效分析和利用。",
      link: "data",
    },
  ],
  login: false,
});
function toLogin() {
  $$.router.push({ name: "login" });
}
const user = ref();

const noLogin = ref(true);
const access_token = getLocalStorage("accessToken");
if (access_token) {
  getUserInfo({
    data: {},
    headers: {
      accessToken: access_token,
    },
    success: (res) => {
      user.value = res;
      noLogin.value = false;
    },
  });
}
const pageLink = (data) => {
  $$.router.push({ name: data.router, query: { anchor: data.anchor } });
};
const pageLink2 = (link) => {
  $$.router.push({ name: link });
};
const linkToRegister = () => {
  $$.router.push({ name: "register" });
};
const hover = (index) => {
  navHide.value = index === 2;
};
const handleOk = () => {
  console.log("222");
};
window.onscroll = () => {
  const scrollTop =
    document.body.scrollTop + document.documentElement.scrollTop;
  hedaerFixed.value = scrollTop > 100;
};
</script>
<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
.logo{
  a:hover{
    color: #fff;
  }
}
.login-box {
  flex-wrap: wrap;
  padding-bottom: 20px;
  > div {
    width: 50%;
  }
  .left {
    h2 {
      font-size: 26px;
      font-weight: 600;
    }
    /deep/.save-btn {
      display: block;
      width: 80%;
      margin: 20px auto;
    }
  }
}
.sec-nav {
  li {
    &:last-child {
      opacity: 0 !important;
    }
  }
}
</style>
