import IconCard from './IconCard.vue';
import ComTitle from './ComTitle.vue';
import InnerBanner from './InnerBanner.vue';
import ComHeader from './ComHeader.vue';
import ComFooter from './ComFooter.vue';
import TabNav from './TabNav.vue';
import toast from './showModel'

import {
  Button,
  Modal,
  Input,
  Form,
  FormItem,
  Tabs,
  Select,
  Steps,
} from 'ant-design-vue';

const install = function install(app) {
  app.component('IconCard', IconCard);
  app.component('ComTitle', ComTitle);
  app.component('InnerBanner', InnerBanner);
  app.component('ComHeader', ComHeader);
  app.component('ComFooter', ComFooter);
  app.component('TabNav', TabNav);
  app.component(Button.name, Button);
  app.component(Modal.name, Modal);
  app.component(Input.name, Input);
  app.component(Form.name, Form);
  app.component(FormItem.name, FormItem);
  app.component(Tabs.name, Tabs);
  app.component(Tabs.TabPane.name, Tabs.TabPane);
  app.component(Select.name, Select)
  app.component(Steps.name, Steps)
  app.component(Steps.Step.name, Steps.Step)


  $$.toast = toast;
};

export default {
  install,
};