Storage.prototype.setExpire=(key, value, expire) =>{

  localStorage.setItem(key,value);
  localStorage.setItem('expiresIn',expire * 1000 + new Date().getTime());
}

Storage.prototype.getExpire= key =>{
  let val = localStorage.getItem(key);
  let expireIn = localStorage.getItem('expiresIn');
  if(!val){
      return null;
  }

  if( new Date().getTime() > expireIn ){
      localStorage.removeItem(key);
      localStorage.removeItem('expiresIn');
      return null;
  }
  return val;
}

export const setLocalStorage = (key, value, time)=>{
   localStorage.setExpire(key, value, time);
}

export const getLocalStorage = (key)=>{
  return localStorage.getExpire(key)
}