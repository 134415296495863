import {
    createVNode,
    render,
    defineAsyncComponent
} from 'vue'

// 2. 准备一个DOM容器
const div = document.createElement('div')
div.setAttribute('class', 'show-model-wrapper')
div.setAttribute('id', 'showModel')



export default ({
    title,
    link
}) => {

    const vnode = createVNode(defineAsyncComponent(() => import( /* @vite-ignore */ `./index.vue`)), {
        title,
        link
    })
    render(vnode, div)
    document.body.appendChild(div)
}